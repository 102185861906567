<template>
  <v-row v-if="!hasOrderNotFinished" justify="end">
    <v-col
      cols="auto"
      v-if="isShowButtonGoodsReceivedWarehouse"
    >
      <v-btn
        color="grey"
        class="white--text body-2"
        @click="setDialog('dialogGoodsReceived')"
      >
        {{$_strings.order.GOODS_RECEIVED_WAREHOUSE}}
      </v-btn>
    </v-col>
    <v-col cols="auto" v-if="isShowBtnCompletedTheOrder">
      <v-btn
        color="primary"
        class="body-2"
        @click="setDialog('dialogCompleteTheOrder')"
      >
        {{$_strings.order.COMPLETE_THE_ORDER}}
      </v-btn>
    </v-col>
    <v-col cols="auto" v-if="isShowBtnCancelTheOrder">
      <v-btn
        color="red lighten-1"
        class="white--text body-2"
        @click="setDialog('dialogCancelOrder')"
      >
        {{$_strings.order.CANCEL_THE_ORDER}}
      </v-btn>
    </v-col>
    <DialogGoodsReceived
      ref="dialogGoodsReceived"
      @baspConfirmation="baspConfirmation"
    />
    <DialogCancelOrder
      ref="dialogCancelOrder"
      @cancelOrder="cancelOrder"
    />
    <DialogCompleteTheOrder
      ref="dialogCompleteTheOrder"
      @completeOrder="completeOrder"
    />
  </v-row>
</template>

<script>
const DialogGoodsReceived = () => import(/* webpackChunkName: "DialogGoodsReceived" */ '../Dialog/GoodsReceived.vue');
const DialogCompleteTheOrder = () => import(/* webpackChunkName: "DialogCompleteTheOrder" */ '../Dialog/CompleteTheOrder.vue');
const DialogCancelOrder = () => import(/* webpackChunkName: "DialogCancelOrder" */ '../Dialog/CancelOrder.vue');

export default {
  components: {
    DialogGoodsReceived,
    DialogCompleteTheOrder,
    DialogCancelOrder,
  },
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
    shipment: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    hasOrderNotFinished() {
      const { id, orderNumber } = this.shipment;
      const route = this.routes.find((r) => r.shipments && r.shipments.map((s) => s.shipmentsId).includes(+id)) || {};
      const { listShipments } = route;
      let orderFinished = false;
      if (listShipments && orderNumber) {
        orderFinished = listShipments.includes(orderNumber);
      }
      return orderFinished;
    },
    isShowBtnCompletedTheOrder() {
      const {
        orderStatus,
        carton,
        cubic,
        tonnage,
      } = this.shipment;
      const orderCompleted = [
        'Ditolak',
        'Dibatalkan',
        'Kedaluwarsa',
      ];
      if (this.$route.query.status.toUpperCase() !== 'PROCESS') return false;
      if (typeof cubic !== 'number' || typeof carton !== 'number' || typeof tonnage !== 'number') return false;
      return !orderCompleted.includes(orderStatus);
    },
    isShowButtonGoodsReceivedWarehouse() {
      const { orderStatus } = this.shipment;
      const status = ['ba penerimaan konfirmasi', 'bap - konfirmasi shipper', 'ba penerimaan konfirmasi'];
      return status.includes(orderStatus.toLowerCase());
    },
    isShowBtnCancelTheOrder() {
      const {
        orderStatus,
        carton,
        cubic,
        tonnage,
      } = this.shipment;
      if (typeof cubic === 'number' || typeof carton === 'number' || typeof tonnage === 'number') return false;
      const canCancelStatus = [
        'pesanan dibuat',
        'menunggu konfirmasi',
        'menunggu konfirmasi driver',
        'menunggu konfirmasi transporter',
        'ditolak',
        'terkonfirmasi',
        'driver waktu habis',
        'waktu habis',
      ];
      if (canCancelStatus.includes(orderStatus.toLowerCase())) return true;
      return false;
    },
    basePath() {
      const patternGetBasePath = /^(\/[\w-]*){2}/;
      const path = this.$route.path.match(patternGetBasePath)[0];
      return path;
    },
  },
  methods: {
    setDialog(dialogBy) {
      this.$refs[dialogBy].order = this.shipment;
      this.$refs[dialogBy].dialog = !this.$refs[dialogBy].dialog;
    },
    async cancelOrder(id, form) {
      try {
        this.$refs.dialogCancelOrder.isLoading = true;
        await this.$_services.order.cancelShipment(id, form);
        this.$dialog.notify.success('Berhasil membatalkan pesanan');
        this.$router.replace(`${this.basePath}/list/finished`);
      } finally {
        this.$refs.dialogCancelOrder.isLoading = false;
        this.setDialog('dialogCancelOrder');
      }
    },
    async completeOrder(id, form) {
      this.$refs.dialogCompleteTheOrder.isLoading = true;
      try {
        await this.$_services.order.completeOrder(id, form);
        this.$dialog.notify.success('Berhasil menyelesaikan pesanan');
        this.$router.replace(`${this.basePath}/list/finished`);
      } finally {
        this.$refs.dialogCompleteTheOrder.isLoading = false;
        this.setDialog('dialogCompleteTheOrder');
      }
    },
    async baspConfirmation(id, form) {
      this.$refs.dialogGoodsReceived.isLoading = true;
      try {
        await this.$_services.order.baspConfirmation(id, form);
        this.$dialog.notify.success('Berhasil');
        this.$router.replace(`${this.basePath}/list/finished`);
      } finally {
        this.$refs.dialogGoodsReceived.isLoading = false;
        this.setDialog('dialogGoodsReceived');
      }
    },
  },
};
</script>
